body {
  background-color: #9b9b9b;
}

.app-container {
  padding: 25px;
}

.main-container {
  max-width: 390px;
  background-color: #e5e5e5;
  margin: 0 auto;
  border-radius: 10px;
  height: 800px;
  margin-top: 50px;
}

.filter-container {
  margin: 0 auto;
  margin-top: 50px;
}

.activity-header {
  display: flex;
  justify-content: space-between;
}

.activity-container {
  margin-top: 30px;
}

.activity-percentage {
  align-self: center;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: #6d6d6d;
}

.activity-container h2 {
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #423838;
  margin-bottom: unset;
}

progress {
  -webkit-appearance: none;
  width: 100%;
  border-radius: 30px;
  height: 5px;
}

progress::-webkit-progress-bar {
  border: 0;
  height: 5px;
  border-radius: 30px;
}
progress::-webkit-progress-value {
  border: 0;
  height: 5px;
  border-radius: 30px;
}
progress::-moz-progress-bar {
  border: 0;
  height: 5px;
  border-radius: 30px;
}

.progess-bar::-webkit-progress-bar {
  background-color: #C8D2D0;
}

.progess-bar::-webkit-progress-value {
    background-color: #161616;
}

.activities {
  margin-bottom: 10px;
  margin-top: 10px;
}