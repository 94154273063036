.hero {
  padding-top: 20px;
}

.card {
  background-image: url("../../images/card-hero.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 300px;
  margin: 0 auto;
  border-radius: 10px;
  padding: 15px 20px;
  color: #fff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.card-title {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
}

.card-description {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.03em;
  max-width: 222px;
  margin-bottom: 10px;
}

.card-button-container {
  display: flex;
  flex-direction: row-reverse;
}

.card-button {
  background: #ffffff;
  border-radius: 20px;
  padding: 12px 20px;
  outline: none;
  border: none;
  color: #423838;
}

button {
    cursor: pointer;
}