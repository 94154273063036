.activity {
  background-color: #f4f6f6;
  margin-bottom: 10px;
  padding: 20px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 60px 200px auto;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.activity-label {
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
}

.activity-description {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #6D6D6D;
  width: 150px;
}

.activity-completed {
    background-color: #cfcfcf;
}